"use client";
import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  Suspense,
} from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { analytics, resolveIdentify } from "@/app/lib/analytics/segment";
import { Traits } from "@/hooks/analytics/types";
import { usePathname, useSearchParams } from "next/navigation";
import useAnalytics from "@/hooks/analytics";

const PageTracker: FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.page();
  }, [pathname, searchParams]);

  return null;
};

export const AnalyticsContext = createContext<AnalyticsBrowser>(analytics);

const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  useEffect(() => {
    async function identify() {
      if (analytics && !sessionUser?.loading) {
        if (sessionUser?.data) {
          const traits: Traits = {
            name: sessionUser.data.name,
            email: sessionUser.data.email,
            phone: sessionUser.data.phone,
          };
          await analytics.identify(String(sessionUser.data.id), traits);
          resolveIdentify(traits);
        }
      }
    }

    identify();
  }, [sessionUser?.loading, sessionUser?.data?.id]);
  useEffect(() => {
    if (!sessionUser?.loading && !sessionUser?.data) {
      resolveIdentify(null);
    }
  }, [sessionUser?.loading, sessionUser?.data]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Suspense>
        <PageTracker />
      </Suspense>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
