"use client";
import {
  useCombobox,
  UseComboboxGetInputPropsOptions,
  UseComboboxProps,
  UseComboboxReturnValue,
} from "downshift";
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  JSX,
  ForwardRefRenderFunction,
  useImperativeHandle,
  ForwardedRef,
} from "react";
import clsx from "clsx";
import Option from "./components/Option";
import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  List,
  Spinner,
} from "@chakra-ui/react";

type BaseControlProps<T> = Pick<
  FormControlProps,
  "isDisabled" | "isInvalid" | "isReadOnly" | "isRequired" | "label"
> &
  T & {
    isLoading?: boolean;
    helperText?: string;
    errorMessage?: string;
    render: (props: T) => JSX.Element;
  };

export type ControlProps<T> = Omit<BaseControlProps<T>, "render">;

function ControlView<T>(
  {
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    label,
    helperText,
    errorMessage,
    render,
    ...rest
  }: BaseControlProps<T>,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      ref={ref}
    >
      {label && <FormLabel fontSize="small">{label}</FormLabel>}
      {render(rest as T)}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

const Control = forwardRef(ControlView) as <T>(
  props: BaseControlProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReturnType<typeof ControlView>;

interface InputFieldProps extends InputProps {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  isLoading?: boolean;
}
const InputFieldView: ForwardRefRenderFunction<
  HTMLInputElement,
  ControlProps<InputFieldProps>
> = ({ leftElement, rightElement, isLoading, ...props }, ref) => {
  return (
    <Control<InputProps>
      {...props}
      render={(props) => (
        <InputGroup alignItems="center">
          {leftElement && (
            <InputLeftElement pointerEvents="none" color="gray.400">
              {leftElement}
            </InputLeftElement>
          )}
          <Input autoComplete="off" {...props} ref={ref} />
          {isLoading != null && (
            <InputRightElement h={"100%"} pointerEvents="none" color="gray.400">
              {isLoading && <Spinner color="blue.500" size="sm" />}
            </InputRightElement>
          )}
          {rightElement && (
            <InputRightElement pointerEvents="none" color="gray.400">
              {rightElement}
            </InputRightElement>
          )}
        </InputGroup>
      )}
    />
  );
};

const InputField = forwardRef(InputFieldView);

export type SearchAutocompleteProps<T> = Partial<UseComboboxProps<T>> & {
  items: T[];
  renderItem: (item: T) => ReactNode;
  inputProps: Omit<UseComboboxGetInputPropsOptions, "size"> &
    ControlProps<InputProps>;
  menuProps?: HTMLAttributes<HTMLUListElement>;
  comboboxRef?: ForwardedRef<UseComboboxReturnValue<T>>;
};

function SearchAutocompleteView<
  T extends {
    id: string | number;
  },
>(
  {
    inputProps: { as, width, height, id, size, color, label, ...input },
    menuProps,
    comboboxRef,
    ...props
  }: SearchAutocompleteProps<T>,
  ref: Ref<HTMLInputElement>,
) {
  const combobox = useCombobox<T>(props);

  useImperativeHandle(comboboxRef, () => combobox, [combobox]);

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    highlightedIndex,
    selectedItem,
  } = combobox;
  const inputProps = getInputProps(input, {
    suppressRefError: true,
  });

  return (
    <Box className="relative">
      <Box className="gap-1">
        <InputField
          {...inputProps}
          size={size}
          id={id}
          label={label}
          as={as}
          width={width}
          height={height}
          color={color}
          ref={ref}
        />
      </Box>
      <List
        key={props.items.length}
        {...getMenuProps({
          ...menuProps,
          className: clsx(
            "absolute z-10 mt-1 max-h-80 w-full overflow-scroll bg-white p-0 shadow-md",
            menuProps?.className,
            {
              hidden: !(isOpen && props.items.length),
            },
          ),
        })}
      >
        {isOpen
          ? props.items.map((item, index) => (
              <Option
                key={index}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                {...getItemProps({
                  index,
                  item,
                })}
                renderItem={props.renderItem}
                index={index}
                item={item}
              />
            ))
          : null}
      </List>
    </Box>
  );
}

const SearchAutocomplete = forwardRef(SearchAutocompleteView) as <T>(
  props: SearchAutocompleteProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReturnType<typeof SearchAutocompleteView>;

export default SearchAutocomplete;
