"use client";
import { FC, useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

const Datadog: FC = () => {
  const initialised = useRef(false);
  useEffect(() => {
    // NOTE: this regex is used to filter out the error from Outlook smth error:
    // Object Not Found Matching Id:6, MethodName:update, ParamCount:4
    // https://github.com/DataDog/browser-sdk/issues/2715
    const regex =
      /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/;
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPID;
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;

    if (applicationId && clientToken && !initialised.current) {
      datadogRum.init({
        applicationId,
        clientToken,
        site: "datadoghq.com",
        service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
        env: process.env.NODE_ENV,
        version: "0.0.0",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
        beforeSend: (event) => {
          // TODO(@oleksii.a): remove this once we fix the issue with Chakra and hydration
          return !(
            event.type === "error" &&
            (event.error.message.includes("Minified React error #418") ||
              regex.test(event.error.message))
          );
        },
        allowedTracingUrls: [
          {
            match: String(process.env.NEXT_PUBLIC_BACKEND_BASE_URL),
            propagatorTypes: ["tracecontext"],
          },
        ],
      });
      initialised.current = true;
    } else {
      console.info(
        "[DataDog]: either applicationId or clientToken is not configured.",
      );
    }
  }, []);

  return null;
};

export default Datadog;
