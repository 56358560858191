"use client";
import { useCallback, useContext, useMemo } from "react";
import { AnalyticsContext } from "@/app/AnalyticsProvider";
import { BaseEventProperties, EventName } from "@/hooks/analytics/types";
import { useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { usePathname } from "next/navigation";
import { EventSource } from "@/app/lib/analytics/types";
import { globalSearchReactiveVar } from "@/components/Search";
import { StoreContext } from "@/app/StoreProvider";

const usePathnameEventSource = () => {
  const pathname = usePathname();

  if (pathname === "/") {
    return EventSource.HOME;
  }
  const [base] = pathname.split("/").filter(Boolean);

  switch (base) {
    case "lists":
      return EventSource.SHOPPING_LIST;
    case "department":
    case "sku":
      return EventSource.CATALOG;
    case "profile":
      return EventSource.PROFILE;
    case "search":
      return EventSource.SEARCH_RESULTS;
    case "orders":
      return EventSource.DELIVERIES;
    case "checkout":
      return EventSource.CHECKOUT;
    default:
      return undefined;
  }
};

function useAnalytics<T extends BaseEventProperties>(source?: EventSource) {
  const analytics = useContext(AnalyticsContext);
  const pathnameEventSource = usePathnameEventSource();
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const store = useContext(StoreContext);
  const { suid } = useReactiveVar(globalSearchReactiveVar);
  const baseEventProperties: BaseEventProperties = useMemo(() => {
    return {
      source_object: source || pathnameEventSource,
      suid,
      vendor_id: store?.vendor?.id,
      vendor_name: store?.vendor?.name,
      store_id: store?.id,
      store_name: store?.name,
      ...(sessionUser?.data && {
        current_restaurant: {
          restaurant_id: sessionUser?.data?.restaurantID,
          cuisine_type: sessionUser?.data?.restaurant?.cuisineType?.name,
        },
      }),
    };
  }, [source, pathnameEventSource, suid, store, sessionUser?.data]);

  const track = useCallback(
    async (event: EventName, properties?: T) => {
      return analytics?.track(event, {
        ...baseEventProperties,
        ...properties,
      });
    },
    [analytics, baseEventProperties],
  );
  return { track, page: analytics.page };
}

export default useAnalytics;
