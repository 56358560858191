import { gql } from "@apollo/client";

export const QUERY_AUTOCOMPLETE = gql`
  query Autocomplete($search: String!, $limit: Int!) {
    autocomplete(search: $search, limit: $limit) {
      suggestions {
        title
      }
    }
  }
`;
