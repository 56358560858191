"use client";
import { FC, FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  makeVar as makeLove,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import { QUERY_AUTOCOMPLETE } from "@/documents/QUERY_AUTOCOMPLETE";
import {
  AutocompleteQuery,
  AutocompleteQueryVariables,
} from "@/documents/__generated__/QUERY_AUTOCOMPLETE.codegen";
import debounce from "lodash/debounce";
import SearchAutocomplete from "@/components/Autocomplete";
import { Box, Container, Progress, useBreakpointValue } from "@chakra-ui/react";
import { useParams, useRouter } from "next/navigation";
import { Link } from "@chakra-ui/next-js";
import { UseComboboxReturnValue } from "downshift";
import { useBoolean } from "@chakra-ui/icons";
import { AbortError } from "@/utils/abortError";
import { useSearchEvent } from "@/hooks/analytics/trackers/useSearchEvent";
import { EventAction } from "@/app/lib/analytics/types";

const DEBOUNCE_MS = 400;
const SEARCH_MIN_LENGTH = 3;

export const globalSearchReactiveVar = makeLove<{
  searching: boolean;
  suid?: string;
}>({
  searching: false,
});

const Search: FC = () => {
  const abortController = useRef<AbortController>(null);
  const { searchTerm } = useParams();
  const { trackSearch, trackSearchResults } = useSearchEvent();
  const router = useRouter();
  const [isNavigating, { on, off }] = useBoolean();
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { searching, suid } = useReactiveVar(globalSearchReactiveVar);
  const [query, { data, loading }] = useLazyQuery<
    AutocompleteQuery,
    AutocompleteQueryVariables
  >(QUERY_AUTOCOMPLETE);
  useEffect(() => {
    if (searchTerm && suid) {
      trackSearchResults(EventAction.VIEW, {
        search_term: String(searchTerm),
      });
    }
  }, [searchTerm, suid, trackSearchResults]);
  const abortLatest = () =>
    abortController.current && abortController.current.abort(new AbortError(0));
  const comboboxRef = useRef<
    UseComboboxReturnValue<{
      key: string;
      value: string;
    }>
  >(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isNavigatingRef = useRef(isNavigating);

  useEffect(() => {
    if (searching && !isNavigating) {
      globalSearchReactiveVar({
        searching: false,
        suid,
      });
    }
  }, [searching, isNavigating, suid]);
  useEffect(() => {
    isNavigatingRef.current = isNavigating;
  }, [isNavigating]);

  useEffect(() => {
    off();
    globalSearchReactiveVar({
      searching: false,
      suid,
    });
    if (searchTerm) {
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length,
        );
      }, 0);
    } else {
      comboboxRef.current?.reset();
    }
  }, [inputRef, searchTerm]);

  const onSearch = useMemo(() => {
    return debounce((search: string) => {
      if (
        !search ||
        search.length < SEARCH_MIN_LENGTH ||
        isNavigatingRef.current
      ) {
        return;
      }

      abortController.current = new AbortController();

      return query({
        variables: {
          search,
          limit: 10,
        },
        context: {
          fetchOptions: {
            signal: abortController.current.signal,
          },
          skipErrorLogging: true,
        },
      }).finally(() => {
        globalSearchReactiveVar({
          searching: false,
          suid,
        });
      });
    }, DEBOUNCE_MS);
  }, [isNavigatingRef, query]);

  const items = useMemo(
    () =>
      data?.autocomplete.suggestions?.map((suggestion) => ({
        key: suggestion.title,
        value: suggestion.title,
      })) || [],
    [data],
  );

  const props = useBreakpointValue(
    {
      base: {
        size: "sm",
        fontSize: "sm",
      },
      lg: {
        size: "lg",
        fontSize: "md",
      },
    },
    {
      fallback: "lg",
    },
  );

  return (
    <Container>
      {searching && (
        <Box className="fixed z-50 top-0 right-0 left-0">
          <Progress size="xs" isIndeterminate />
        </Box>
      )}
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          abortLatest();
          const formData = new FormData(event.currentTarget);
          const { search } = Object.fromEntries(formData.entries());
          if (search !== decodeURIComponent(String(searchTerm))) {
            on();
            globalSearchReactiveVar({
              searching: true,
              suid,
            });
            comboboxRef.current?.closeMenu();
            router.push(`/search/${encodeURIComponent(String(search))}`);
          }
        }}
      >
        <SearchAutocomplete
          ref={inputRef}
          comboboxRef={comboboxRef}
          onHighlightedIndexChange={({ highlightedIndex }) => {
            setHighlightedIndex(highlightedIndex);
          }}
          defaultInputValue={
            searchTerm && decodeURIComponent(String(searchTerm))
          }
          itemToString={(item) => item?.value || ""}
          items={items}
          renderItem={(item) => (
            <Link
              href={`/search/${encodeURIComponent(item.value)}`}
              prefetch={false}
              className="flex p-3"
            >
              {item.value}
            </Link>
          )}
          inputProps={{
            ...props,
            onChange: (e) => {
              onSearch(e.currentTarget.value);
            },
            onFocus: (e) => {
              trackSearch(EventAction.FOCUSED, {
                search_term: e.currentTarget.value,
              });
            },
            onBlur: (e) => {
              trackSearch(EventAction.BLURRED, {
                search_term: e.currentTarget.value,
              });
            },
            placeholder: "Search for items, categories, brands and more",
            name: "search",
            isLoading: loading,
            isDisabled: isNavigating,
            "aria-label": "Search",
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                if (highlightedIndex === -1) {
                  // Prevent Downshift's default 'Enter' behavior.
                  // Submitting the form will navigate to the search page.
                  (
                    event.nativeEvent as KeyboardEvent & {
                      preventDownshiftDefault: boolean;
                    }
                  ).preventDownshiftDefault = true;
                } else {
                  const item = items[highlightedIndex];
                  if (item) {
                    router.push(`/search/${item.value}`);
                  }
                }
              }
            },
          }}
        />
      </form>
    </Container>
  );
};

export default Search;
