import { SessionUser } from "@/hooks/user/utils";
import { Maybe } from "@/documents/__generated__/globalTypes.codegen";

export type AuthError = {
  id: number;
  code: string;
  description: string;
};

export type Session = {
  data?: SessionUser;
  loading: boolean;
  error?: Maybe<AuthError>;
};

export type SessionCookies = {
  authToken?: string;
  restaurantID?: string;
  invitationToken?: string;
  deviceID?: string;
  anonymousUUID?: string;
};

export const InvalidTokenError: AuthError = {
  id: 1,
  code: "invalid_token",
  description: "Session has been expired",
};
export const MissingRestaurantError: AuthError = {
  id: 2,
  code: "missing_restaurant",
  description: "No restaurants have been found for this user",
};

export const getBaseHeaders = () => {
  const headers = new Headers();
  headers.set("PUBLIC-API-TOKEN", String(process.env.NEXT_PUBLIC_API_TOKEN));
  headers.set("CLIENT-VERSION", String(process.env.NEXT_PUBLIC_CLIENT_VERSION));
  headers.set("Content-Type", "application/json");

  return headers;
};
