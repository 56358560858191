import { ReactNode } from "react";
import clsx from "clsx";
import { ListItem, ListItemProps } from "@chakra-ui/react";

type OptionProps<T> = ListItemProps & {
  highlightedIndex: number;
  index: number;
  item: T;
  selectedItem: T | null;
  renderItem: (item: T) => ReactNode;
};

function Option<T>({
  highlightedIndex,
  index,
  selectedItem,
  item,
  renderItem,
  ...props
}: OptionProps<T>) {
  return (
    <ListItem
      _hover={{ bg: "gray.200" }}
      cursor="pointer"
      className={clsx({
        "bg-gray-200": highlightedIndex === index,
        "font-bold bg-gray-200": selectedItem === item,
      })}
      {...props}
    >
      {renderItem(item)}
    </ListItem>
  );
}

export default Option;
