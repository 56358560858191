"use client";
import { createContext, FC, PropsWithChildren } from "react";
import { DeepPartial } from "@apollo/client/utilities";
import { Store } from "@/documents/__generated__/globalTypes.codegen";

export const StoreContext = createContext<
  DeepPartial<Store> | null | undefined
>(null);

const StoreProvider: FC<
  PropsWithChildren<{
    store?: DeepPartial<Store> | null;
  }>
> = ({ store, children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
