"use client";
import { FC, Fragment, PropsWithChildren, useEffect } from "react";
import {
  InvalidTokenError,
  MissingRestaurantError,
  Session,
} from "@/app/lib/utils";
import isDefined from "@/utils/isDefined";
import { parseUser } from "@/hooks/user/utils";
import { getCookie } from "cookies-next/client";
import { RESTAURANT_ID_COOKIE_NAME, TOKEN_COOKIE_NAME } from "@/apollo/utils";
import { FragmentUserFragment } from "@/documents/fragments/__generated__/FRAGMENT_USER.codegen";
import { useConst } from "@chakra-ui/hooks";
import { makeVar as makeLove } from "@apollo/client";
import { UserState } from "@/app/actions/auth";

type UserProviderProps = {
  userFragment?: FragmentUserFragment;
};

export const sessionUserReactiveVar = makeLove<UserState>({
  loading: true,
  data: undefined,
});

const UserProvider: FC<PropsWithChildren<UserProviderProps>> = ({
  children,
  userFragment,
}) => {
  const sessionUser = useConst(() => {
    const { authToken, restaurantID } = {
      authToken: getCookie(TOKEN_COOKIE_NAME),
      restaurantID: getCookie(RESTAURANT_ID_COOKIE_NAME),
    };
    let sessionUser: Session = {
      loading: false,
      data: undefined,
    };
    if (userFragment && authToken) {
      const restaurants =
        userFragment.restaurants?.nodes?.filter(isDefined) || [];
      if (!userFragment.name && !userFragment.phone && !userFragment.email) {
        sessionUser = {
          loading: false,
          error: InvalidTokenError,
        };
      } else if (restaurants.length === 0) {
        sessionUser = {
          loading: false,
          error: MissingRestaurantError,
        };
      } else if (restaurants.length > 0) {
        const [restaurant, ...rest] = restaurants;

        sessionUser = {
          loading: false,
          data: parseUser(
            userFragment,
            [restaurant, ...rest],
            authToken,
            restaurantID,
          ),
        };
      }
    }

    return sessionUser;
  });

  useEffect(() => {
    sessionUserReactiveVar(sessionUser);
  }, [sessionUser]);

  return <Fragment key={sessionUser.data?.id}>{children}</Fragment>;
};

export default UserProvider;
