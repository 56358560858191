import { AnalyticsBrowser } from "@segment/analytics-next";
import noop from "lodash/noop";

export let resolveIdentify: (value: unknown) => void = noop;
const identifyPromise = new Promise((resolve) => {
  resolveIdentify = resolve;
});

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY!,
});

// Wait for the identify promise to resolve before sending any events
analytics.addSourceMiddleware(async ({ payload, next }) => {
  if (payload.type() === "identify") {
    return next(payload);
  }

  await identifyPromise;

  return next(payload);
});

if (process.env.NEXT_PUBLIC_DEBUG_SEGMENT) {
  analytics.addSourceMiddleware(({ payload, next }) => {
    console.groupCollapsed(
      `%c📊 Analytics%c ${payload.obj.type}${payload.obj.event ? ` %c${payload.obj.event}` : ""}`,
      "color: #2563eb; font-weight: bold;", // Blue for "Analytics"
      "color: #9333ea; font-weight: bold;", // Purple for event type
      ...(payload.obj.event ? ["color: #059669; font-weight: bold;"] : []), // Green for event name
    );

    if (payload.obj.properties) {
      console.log(
        "%cProperties%c",
        "color: #7c3aed; font-weight: bold; border-bottom: 1px solid #7c3aed;",
        "",
        payload.obj.properties,
      );
    }

    if (payload.obj.traits) {
      console.log(
        "%cTraits%c",
        "color: #7c3aed; font-weight: bold; border-bottom: 1px solid #7c3aed;",
        "",
        payload.obj.traits,
      );
    }

    console.groupEnd();

    return next(payload);
  });
}
