import { DeepPartial } from "@apollo/client/utilities";
import {
  NullMembership,
  Restaurant as RestaurantType,
  User,
} from "@/documents/__generated__/globalTypes.codegen";
import isDefined from "@/utils/isDefined";

export type SessionUser = DeepPartial<User> & {
  id: number;
  restaurantID: number;
  authToken: string;
  isAdmin: boolean;
  restaurant?:
    | (Restaurant & {
        address?: string;
        nullMembership?: DeepPartial<NullMembership>;
      })
    | null;
};

export const getAddress = (restaurant: DeepPartial<Restaurant>) => {
  if (restaurant.currentAddress) {
    const { addressLine, city, state, zipcode } = restaurant.currentAddress;

    return [addressLine, city, state, zipcode].filter(isDefined).join(", ");
  }
};

const getNullMembership = (restaurant: DeepPartial<Restaurant>) => {
  if (
    restaurant.membership &&
    restaurant.membership.__typename === "NullMembership"
  ) {
    return restaurant.membership;
  }
};

type Restaurant = DeepPartial<RestaurantType> & {
  id: number;
};

export const parseUser = (
  user: DeepPartial<User>,
  restaurants: [Restaurant, ...Restaurant[]],
  authToken: string,
  restaurantID?: string,
): SessionUser => {
  const id = Number(restaurantID);
  const first = restaurants[0];
  const restaurant =
    restaurants.find((restaurant) => restaurant?.id === id) || first;
  const isAdmin = restaurant?.userRole === "admin";
  if (!user.id) {
    throw new Error("User ID is required");
  }
  return {
    ...user,
    id: user.id,
    isAdmin,
    authToken,
    restaurantID: restaurant.id,
    restaurant: {
      ...restaurant,
      nullMembership: getNullMembership(restaurant),
      address: getAddress(restaurant),
    },
  };
};
