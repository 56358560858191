const reasons = new Map([[0, "The operation was aborted by the user"]]);

const getMessage = (code: number) => {
  if (reasons.has(code)) {
    return reasons.get(code);
  }

  return "Unknown abort reason";
};

export class AbortError extends Error {
  readonly name: string = "AbortError";
  readonly code: number;
  constructor(code: number) {
    super(getMessage(code));
    this.code = code;
  }
}
