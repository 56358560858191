"use client";
import { ReactNode, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./globals.css";
import { Wrappers } from "@/app/Wrappers";
import localFont from "next/font/local";
import Datadog from "@/app/Datadog";

const basisGrotesquePro = localFont({
  src: [
    {
      path: "./BasisGrotesquePro-Light.woff2",
      weight: "300",
    },
    {
      path: "./BasisGrotesquePro-Regular.woff2",
      weight: "400",
    },
    {
      path: "./BasisGrotesquePro-Bold.woff2",
      weight: "700",
    },
  ],
  variable: "--font-basis-grotesque-pro",
  fallback: ["Arial", "Helvetica", "sans-serif"],
});

export default function RootLayout({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  // We have a plenty of hydration errors because of Chakra UI, even with a very simple components like <Text/>
  // There's an issue: https://github.com/chakra-ui/chakra-ui/issues/7941
  // Which doesn't seem to be resolved so we might consider bumping Chakra to v3
  // TODO: investigate possible solution
  useEffect(() => {
    window.addEventListener("error", (event) => {
      if (event.message.includes("Minified React error #418")) {
        event.preventDefault();
      }
    });
  }, []);

  return (
    <html
      lang="en"
      suppressHydrationWarning
      className={`${basisGrotesquePro.variable} subpixel-antialiased`}
    >
      <body className="chakra-ui-light">
        <Datadog />
        <Wrappers>{children}</Wrappers>
      </body>
    </html>
  );
}
