import useAnalytics from "@/hooks/analytics";
import {
  EventName,
  SearchEventMetadata,
  SearchEventProperties,
} from "@/hooks/analytics/types";
import { useCallback } from "react";
import { EventAction, EventObject } from "@/app/lib/analytics/types";

export const useSearchEvent = () => {
  const { track } = useAnalytics<SearchEventProperties>();
  const trackSearch = useCallback(
    (action: EventAction, searchMetadata?: SearchEventMetadata) => {
      const eventName: EventName = `${EventObject.SEARCH} ${action}`;

      return track(eventName, searchMetadata);
    },
    [track],
  );
  const trackSearchResults = useCallback(
    (action: EventAction, searchMetadata?: SearchEventMetadata) => {
      const eventName: EventName = `${EventObject.SEARCH_RESULTS} ${action}`;

      return track(eventName, searchMetadata);
    },
    [track],
  );
  return {
    trackSearch,
    trackSearchResults,
  };
};
