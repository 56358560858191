
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "HomePageUnion": [
      "BannerListComponent",
      "CuisineTypeComponent",
      "DealOfTheWeekComponent",
      "ProductListComponent",
      "SalesRepresentativeComponent"
    ],
    "IBanner": [
      "Banner",
      "WebBanner"
    ],
    "ICategory": [
      "Category",
      "PromotionCategory",
      "RecommendedCategory"
    ],
    "IComponent": [
      "BannerListComponent",
      "CuisineTypeComponent",
      "DealOfTheWeekComponent",
      "ProductListComponent",
      "SalesRepresentativeComponent"
    ],
    "IComponentProducts": [
      "ProductListComponent",
      "SalesRepresentativeComponent"
    ],
    "ISubscription": [
      "BasicSubscription",
      "Membership"
    ],
    "MembershipUnion": [
      "Membership",
      "NullMembership"
    ],
    "Node": [
      "BannerListComponent",
      "BasicSubscription",
      "Category",
      "Cuisine",
      "CuisineTypeComponent",
      "DealOfTheWeekComponent",
      "Order",
      "OrderDelivery",
      "ProductListComponent",
      "RecurringOrder",
      "SalesRepresentativeComponent",
      "Shipment",
      "ShoppingSession"
    ],
    "RecommendationUnion": [
      "GoogleAiRecommendedProduct",
      "RecommendedProduct"
    ]
  }
};
      export default result;
    