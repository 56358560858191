import omit from "lodash/omit";
import { extendTheme } from "@chakra-ui/react";

import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { cardTheme } from "@/theme/card";

const fullConfig = resolveConfig(tailwindConfig);

export const theme = extendTheme({
  components: {
    Card: cardTheme,
  },
  fonts: {
    heading: "var(--font-basis-grotesque-pro)",
    body: "var(--font-basis-grotesque-pro)",
  },
  colors: omit(fullConfig.theme.colors, ["inherit"]),
  sizes: {
    container: {
      full: "1600px",
      xs: "340px",
    },
  },
});
