import { theme } from "@chakra-ui/react";
import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const variants = {
  brand: definePartsStyle(() => {
    return {
      container: {
        ...theme.components.Card.variants?.outline.container,
        borderColor: "white",
      },
    };
  }),
};

// export variants in the component theme
export const cardTheme = defineMultiStyleConfig({ variants });
