import type { Config } from "tailwindcss";

export default {
  content: [
    "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    extend: {
      boxShadow: {
        "elevation-1": "0px 1px 6px -1px rgba(77, 79, 93, 0.25)",
        "elevation-2": "0px 2px 12px rgba(77, 79, 93, 0.2)",
      },
      colors: {
        background: "var(--background)",
        foreground: "var(--foreground)",
        list: {
          50: "#9d562e",
          100: "#da2f24",
          200: "#eb942e",
          300: "#f2c629",
          400: "#bbd662",
          500: "#7ba858",
          600: "#76d8cc",
          700: "#2ba6db",
          800: "#3b75cd",
          900: "#3c39d1",
          1000: "#8d2dd8",
          1100: "#d324ad",
        },
        light: {
          500: "#ffffff",
          600: "#f9f9f9",
          700: "#f5f5f6",
          800: "#f2f3f4",
        },
        midnight_navy: {
          500: "#161E30", // Base color
          600: "#262f4c", // Distinctly lighter
          700: "#374369", // Significantly lighter
        },
        blue: {
          50: "#f5f8ff",
          100: "#eaf1ff",
          200: "#cbe0ff",
          300: "#9cc1ff",
          400: "#4d92ff",
          500: "#3A61F0",
          600: "#3456da",
          700: "#2c4bbf",
          800: "#2540a4",
          900: "#1d3589",
        },
      },
    },
  },
  plugins: [],
} satisfies Config;
