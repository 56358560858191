export enum EventSource {
  SHOPPING_LIST = "shopping_list",
  CART = "cart",
  CHECKOUT = "checkout",
  DELIVERIES = "deliveries",
  CATALOG = "catalog",
  PROFILE = "profile",
  SEARCH_RESULTS = "search_results",
  DEPARTMENT = "department",
  CART_RECOMMENDATIONS = "cart_recommendations",
  CART_FREQUENTLY_BOUGHT_TOGETHER_RECOMMENDATIONS = "cart_frequently_bought_together_recommendations",
  CART_RECENTLY_VIEWED_RECOMMENDATIONS = "cart_recently_viewed_recommendations",
  PRODUCT_OTHER_YOU_MAY_LIKE = "product_other_you_may_like",
  DEEP_LINK = "deep_link",
  CHECKOUT_SUCCESS_RECOMMENDATIONS = "checkout_success_recommendations",
  PRODUCT_FREQUENTLY_BOUGHT_TOGETHER_RECOMMENDATIONS = "product_frequently_bought_together_recommendations",
  CHECKOUT_RECOMMENDATIONS = "checkout_recommendations",
  SEARCH_RECOMMENDATIONS = "search_recommendations",
  UNAVAILABLE_PRODUCT = "unavailable_product",
  PRODUCT = "product",
  DELIVERY = "delivery",
  SHIPMENT = "shipment",
  SHOPPING_LIST_REPLACED_ITEM_DIALOG = "shopping_list_replaced_item_dialog",
  CART_REPLACED_ITEM_DIALOG = "cart_replaced_item_dialog",
  DISH = "dish",
  HOME = "home",
  RECURRING_ORDER = "recurring_order",
}

export enum EventAction {
  TAP_CART = "Tap Cart",
  FEEDBACK_VIEW = "Feedback View",
  PROMPT_VIEW = "Prompt View",
  SHARE_VIEW = "Share View",
  TAP_CANCEL = "Tap Cancel",
  TAP_FEEDBACK_CTA = "Tap Feedback CTA",
  TAP_PROMPT_CTA = "Tap Prompt CTA",
  APP_STORE_RATING = "App Store Rating",
  TAP = "Tap",
  SWIPE = "Swipe",
  VIEW = "View",
  CANCEL_CONFIRM = "Cancel Confirm",
  EMPTY_CONFIRM = "Empty Confirm",
  TAP_ADD_PREVIOUS_CART_ITEMS = "Tap Add Previous Cart Items",
  TAP_CHECKOUT = "Tap Checkout",
  TAP_EMPTY = "Tap Empty",
  TAP_SEARCH = "Tap Search",
  SCROLLED_TO_RECOMMENDATIONS = "Scrolled To Recommendations",
  TAP_HEADER_SHOW_ALL = "Tap Header Show All",
  TAP_LIST_SHOW_ALL = "Tap List Show All",
  TAP_PREVIOUS_ORDER_MOVE_TO_CART = "Tap Previous Order Move To Cart",
  TAP_PREVIOUS_ORDER_SHOW_ALL = "Tap Previous Order Show All",
  TAP_PREVIOUS_ORDER = "Tap Previous Order",
  TAP_MOVE_TO_CART_CONFIRM = "Tap Move to Cart Confirm",
  TAP_MOVE_TO_CART_CANCEL = "Tap Move to Cart Cancel",
  TAP_ADDRESS = "Tap Address",
  TAP_DELIVERY = "Tap Delivery",
  TAP_FULFILLMENT_FEE = "Tap Fulfillment Fee",
  TAP_FULFILLMENT_WINDOW = "Tap Fulfillment Window",
  TAP_MEMBERSHIP_UPSELL = "Tap Membership Upsell",
  TAP_ORDER_INSTRUCTIONS = "Tap Order Instructions",
  TAP_PAYMENT_METHOD = "Tap Payment Method",
  TAP_PICKUP = "Tap Pickup",
  TAP_PROMO_CODE = "Tap Promo Code",
  TAP_SUBMIT = "Tap Submit",
  TAP_MEMBERSHIP_STATUS = "Tap Membership Status",
  TAP_CLOSE_MEMBERSHIP_UPSELL = "Tap Close Membership Upsell",
  TAP_CLOSE = "Tap Close",
  TAP_SHARE = "Tap Share",
  OPENED = "Opened",
  TAP_ETA = "Tap ETA",
  TAP_MOVE_ORDER_TO_CART = "Tap Move order to cart",
  TAP_ORDER_DETAILS = "Tap Order details",
  TAP_OUT_OF_STOCK_PRODUCTS = "Tap Out of stock products",
  TAP_PROOF_OF_DELIVERY = "Tap Proof of delivery",
  TAP_REFUSED_PRODUCTS = "Tap Refused products",
  TAP_SUPPORT = "Tap Support",
  VIEW_ORDER_DETAILS = "View Order details",
  VIEW_OUT_OF_STOCK_PRODUCTS = "View Out of stock products",
  VIEW_REFUSED_PRODUCTS = "View Refused products",
  TAP_MOVE_ORDER_TO_CART_CONFIRM = "Tap Move Order To Cart Confirm",
  TAP_MOVE_ORDER_TO_CART_CANCEL = "Tap Move Order To Cart Cancel",
  REMOVE_FILTER_OPTION = "Remove Filter Option",
  TAP_FILTER_CATEGORY = "Tap Filter Category",
  TAP_SHOW_RESULTS = "Tap Show Results",
  TAP_RESET = "Tap Reset",
  REMIND_ME = "Remind Me",
  TAP_REMINDER_OFF = "Tap Reminder Off",
  TAP_REMINDER_ON = "Tap Reminder On",
  TAP_ORDER = "Tap Order",
  TAP_MOVE_TO_CART = "Tap Move To Cart",
  TAP_SUPPORT_REQUEST = "Tap Support Request",
  DIALOG_TAP_CONFIRM = "Dialog Tap Confirm",
  TAP_CONTACT_SUPPORT = "Tap Contact Support",
  TAP_JOIN = "Tap Join",
  REMOVED_FROM_SHOPPING_LIST = "Removed from Shopping List",
  SCROLL_IMAGE_LEFT = "Scroll Image Left",
  SCROLL_IMAGE_RIGHT = "Scroll Image Right",
  SCROLL_TO_BOTTOM = "Scroll To Bottom",
  SUBSTITUTION_OFF = "Substitution Off",
  SUBSTITUTION_ON = "Substitution On",
  TAP_BRAND_INFO = "Tap Brand Info",
  TAP_CASE = "Tap Case",
  TAP_IMAGE = "Tap Image",
  TAP_PROPOSITION_65 = "Tap Proposition 65",
  TAP_UNIT = "Tap Unit",
  ADDED_TO_SHOPPING_LIST = "Added to Shopping List",
  TAP_EXAPND_JIT_INFO = "Tap Exapnd JIT Info",
  TAP_JIT_MORE_INFO = "Tap JIT More Info",
  TAP_EXPAND = "Tap Expand",
  TAP_QUICK_ADD = "Tap Quick Add",
  TAP_THUMBNAIL = "Tap Thumbnail",
  ZOOM = "Zoom",
  CONFIRM = "Confirm",
  SWITCH_TO_CASE = "Switch to Case",
  SWITCH_TO_UNIT = "Switch to Unit",
  TAP_OUT = "Tap Out",
  TAP_IMPORT_SHOPPING_LIST = "Tap Import Shopping List",
  TAP_INVOICES_AND_CREDITS = "Tap Invoices AND Credits",
  TAP_LOG_OUT = "Tap Log Out",
  TAP_MY_ACCOUNT = "Tap My Account",
  TAP_MY_INSTRUCTIONS = "Tap My Instructions",
  TAP_MY_ORDERS = "Tap My Orders",
  TAP_MY_STAFF = "Tap My Staff",
  TAP_MY_SUPPLIER = "Tap My Supplier",
  TAP_PAYMENT_METHODS = "Tap Payment Methods",
  TAP_PLANS = "Tap Plans",
  TAP_REFER_A_FRIEND = "Tap Refer A Friend",
  TAP_SETTINGS = "Tap Settings",
  TAP_VENDOR_ICON = "Tap Vendor Icon",
  COMPLETED = "Completed",
  TAP_CLEAR = "Tap Clear",
  TAP_KEYBOARD_SEARCH = "Tap Keyboard Search",
  TAP_CANT_FIND_WHAT_YOU_RE_LOOKING_FOR = "Tap Can't Find What You re Looking For",
  TAP_REQUEST_A_PRODUCT = "Tap Request A Product",
  TAP_CUSTOM_EDIT = "Tap Custom Edit",
  TAP_SEARCH_CATALOG = "Tap Search Catalog",
  TAP_SORT = "Tap Sort",
  TAP_SORT_BY_A_Z = "Tap Sort By A-Z",
  TAP_SORT_BY_CATEGORY = "Tap Sort By Category",
  TAP_SORT_BY_CUSTOM = "Tap Sort By Custom",
  TAP_START_SHOPPING = "Tap Start Shopping",
  TAP_ADD_STAFF = "Tap Add Staff",
  TAP_SEND = "Tap Send",
  TAP_ADD_IMAGE = "Tap Add Image",
  TAP_CALL = "Tap Call",
  TAP_REMOVE_IMAGE = "Tap Remove Image",
  TAP_SUPPORT_ARTICLE = "Tap Support Article",
  TAP_CATALOG = "Tap Catalog",
  TAP_DELIVERIES = "Tap Deliveries",
  TAP_PROFILE = "Tap Profile",
  TAP_SHOPPING_LIST = "Tap Shopping List",
  TAP_SHOP_FOR_MY_FAMILY = "Tap Shop For My Family",
  TAP_SHOP_FOR_MY_BUSINESS = "Tap Shop For My Business",
  TAP_CONTINUE_AS_A_GUEST = "Tap Continue As A Guest",
  TAP_LOGIN = "Tap Login",
  TAP_SIGNUP = "Tap Signup",
  TAP_CONFIRM = "Tap Confirm",
  CANCEL = "Cancel",
  VIEW_READY_TO_SHIP_TAB = "View Ready To Ship Tab",
  VIEW_STILL_IN_TRANSIT_TAB = "View Still In Transit Tab",
  TAP_MORE_INFO = "Tap More Info",
  TAP_DISMISS = "Tap Dismiss",
  SWIPE_TO_NEXT = "Swipe To Next",
  SWIPE_TO_PREVIOUS = "Swipe To Previous",
  DISMISS = "Dismiss",
  TAP_SCORE = "Tap Score",
  SILENT_PUSH_RECEIVED = "Silent Push Received",
  NOTIFICATION_SHOWN = "Notification Shown",
  NOTIFICATION_TAPPED = "Notification Tapped",
  SEARCH_RESULTS_VIEW = "Search Results View",
  SKIP_TAPPED = "Skip Tapped",
  RESULTS_TAP_CUISINE_TYPE_NOT_LISTED = "Results Tap Cuisine Type Not Listed",
  RESULTS_TAP_RESULT = "Results Tap Result",
  RESULTS_QUERY_CHANGED = "Results Query Changed",
  ADD_NEW_ACCOUNT_TAPPED = "Add New Account Tapped",
  ACCOUNT_TAPPED = "Account Tapped",
  YES_TAPPED = "Yes Tapped",
  NO_TAPPED = "No Tapped",
  CONFIRM_TAPPED = "Confirm Tapped",
  CHECKOUT_TAPPED = "Checkout Tapped",
  CANCEL_TAPPED = "Cancel Tapped",
  SEE_PRODUCTS_TAPPED = "See Products Tapped",
  AVAILABLE_DATE_TAPPED = "Available Date Tapped",
  BANNER_TAPPED = "Banner Tapped",
  UNAVAILABLE_DATE_TAPPED = "Unavailable Date Tapped",
  ACTIVE_ORDERS_TAPPED = "Active Orders Tapped",
  PREVIOUS_ORDERS_TAPPED = "Previous Orders Tapped",
  EDIT_DELIVERY_DETAILS_TAPPED = "Edit Delivery Details Tapped",
  DELIVERY_DATE_TAPPED = "Delivery Date Tapped",
  DELIVERY_TIME_TAPPED = "Delivery Time Tapped",
  SAVE_TAPPED = "Save Tapped",
  DELETE_TAPPED = "Delete Tapped",
  CLOSE_TAPPED = "Close Tapped",
  REMINDER_TAPPED = "Reminder Tapped",
  UP_TAPPED = "Up Tapped",
  DOWN_TAPPED = "Down Tapped",
  SUBMIT_TAPPED = "Submit Tapped",
  COMPONENT_VISIBLE = "Component Visible",
  COMPONENT_BANNER_TAPPED = "Component Banner Tapped",
  COMPONENT_BANNER_DISPLAYED = "Component Banner Displayed",
  COMPONENT_TAPPED_VIEW_ALL = "Component Tapped View All",
  COMPONENT_HORIZONTALLY_SCROLLED = "Component Horizontally Scrolled",
  FOCUSED = "Focused",
  BLURRED = "Blurred",
  TAP_CHANGE_NAME = "Tap Change Name",
  TAP_CHANGE_PASSWORD = "Tap Change Password",
  TAP_DELETE_ACCOUNT = "Tap Delete Account",
  CELL_TAPPED = "Cell Tapped",
  ADD_MEMBER_TAPPED = "Add Members Tapped",
  OK_TAPPED = "Ok Tapped",
  SIGN_UP_TAPPED = "Sign Up Tapped",
  SIGN_IN_TAPPED = "Sign In Tapped",
  ACCEPT_TAPPED = "Accept Tapped",
  DECLINE_TAPPED = "Decline Tapped",
  MAKE_ACCOUNT_OWNER_TAPPED = "Make Account Owner Tapped",
  REMOVE_MEMBER_TAPPED = "Remove Member Tapped",
  SHARE_LINK_TAPPED = "Share Link Tapped",
  ADD_TO_LIST_TAPPED = "Add To List Tapped",
  REMOVE_FROM_LIST_TAPPED = "Remove From List Tapped",
  CREATE_NEW_LIST_TAPPED = "Create New List Tapped",
  LISTS_ACTIONS_TAPPED = "Lists Actions Tapped",
  LIST_ACTION_TAPPED = "List Action Tapped",
  SELECT_ITEM_TAPPED = "Select Item Tapped",
  DESELECT_ITEM_TAPPED = "Deselect Item Tapped",
  MOVE_SELECTED_TO_LIST_TAPPED = "Move Selected To List Tapped",
  REMOVE_SELECTED_TAPPED = "Remove Selected Tapped",
  SELECT_LIST = "Select List",
  DONE_BULK_ACTIONS_TAPPED = "Done Bulk Actions Tapped",
  EDIT_TAPPED = "Edit Tapped",
  CREATE_LIST_TAPPED = "Create List Tapped",
  MOVE_LIST = "Move List",
  REMOVE_TAPPED = "Remove Tapped",
}

export enum EventObject {
  ACTION_BAR = "Action Bar",
  APP_STORE_RATING = "App Store Rating",
  AUTOCOMPLETE_CELL = "Autocomplete Cell",
  BANNER = "Banner",
  CART = "Cart",
  RECOMMENDATION_PRODUCT_LIST = "Recommendation Product List",
  CATALOG = "Catalog",
  CHECKOUT = "Checkout",
  CHECKOUT_CONFIRMATION = "Checkout Confirmation",
  DEEP_LINK = "Deep Link",
  DELIVERIES = "Deliveries",
  PREVIOUS_DELIVERIES = "Previous Deliveries",
  ACTIVE_DELIVERIES = "Active Deliveries",
  DELIVERY = "Delivery",
  DELIVERY_CELL = "Delivery Cell",
  DEPARTMENT = "Department",
  DEPARTMENT_CELL = "Department Cell",
  DEPARTMENT_PRODUCT_LIST = "Department Product List",
  DISCONTINUED_DIALOG = "Discontinued Dialog",
  FILTER_BAR = "Filter Bar",
  FILTER_CATEGORY_CELL = "Filter Category Cell",
  FILTER_OPTION_CELL = "Filter Option Cell",
  FILTERS = "Filters",
  LOGGED_IN = "Logged In",
  OOS_DIALOG = "OOS Dialog",
  ORDER_HISTORY = "Order History",
  ORDER_HISTORY_ORDER = "Order History Order",
  PLAN = "Plan",
  PLAN_CELL = "Plan Cell",
  PLANS = "Plans",
  PRODUCT = "Product",
  PRODUCT_CELL = "Product Cell",
  PRODUCT_IMAGE = "Product Image",
  PRODUCT_PICKER = "Product Picker",
  PROFILE = "Profile",
  RECENT_SEARCH_CELL = "Recent Search Cell",
  REFERRAL = "Referral",
  REGISTRATION = "Registration",
  SEARCH = "Search",
  SEARCH_RESULTS = "Search Results",
  SHOPPING_LIST = "Shopping List",
  STAFF = "Staff",
  STAFF_ADD_STAFF = "Staff Add Staff",
  SUPPORT_REQUEST = "Support Request",
  TAB_BAR = "Tab Bar",
  SIGNUP_LOGIN = "Signup Login",
  SIGNUP_LOGIN_TUTORIAL = "Signup Login Tutorial",
  ANONYMOUS_SIGNUP_LOGIN = "Anonymous Signup Login",
  ORDER_RELEASE_CONSENT_DIALOG = "Order Release Consent Dialog",
  JIT_CENTER = "JIT Center",
  JIT_INFO_DIALOG = "JIT Info Dialog",
  TUTORIAL = "Tutorial",
  JIT_REMINDER_BANNER = "JIT Reminder Banner",
  JIT_INFO_CARD = "JIT Info Card",
  IN_APP_NPS = "In-App NPS",
  SIGNUP_CUISINE_TYPE = "Signup Cuisine Type",
  MULTI_ACCOUNT_LIST = "Multi-Account List",
  MULTI_ACCOUNT_ACCOUNT_ADDED_DIALOG = "Multi-Account Account Added Dialog",
  MULTI_ACCOUNT_ACCOUNT_CHANGE_DIALOG = "Multi-Account Account Change Dialog",
  MOVE_ITEMS_TO_EXISTING_ORDERS_DIALOG = "Move Items to Existing Orders Dialog",
  CHECKOUT_DELIVERY_DETAILS = "Checkout Delivery Details",
  CHOOSE_TIME_DIALOG = "Choose Time Dialog",
  CHOOSE_DATE_DIALOG = "Choose Date Dialog",
  CHECKOUT_DELIVERY_PRODUCTS = "Checkout Delivery Products",
  CHECKOUT_PICKUP_IS_NOT_AVAILABLE_DIALOG = "Checkout Pickup is not available Dialog",
  ACTIVE_ORDER_PRODUCTS = "Active Order Products",
  EDIT_ACTIVE_ORDER = "Edit Active Order",
  DELETE_ACCOUNT_DIALOG = "Delete Account Dialog",
  UNAVAILABLE_PRODUCT_DIALOG = "Unavailable Product Dialog",
  SHOPPING_LIST_SORT_DIALOG = "Shopping List Sort Dialog",
  THUMBS_UP_DOWN = "Thumbs Up/Down",
  THUMBS_UP_DOWN_FEEDBACK = "Thumbs Up/Down Feedback",
  HOME = "Home",
  RECURRING_ORDERS = "Recurring Orders",
  RECURRING_ORDER_CELL = "Recurring Order Cell",
  RECURRING_ORDER_DETAILS = "Recurring Order Details",
  STAFF_LIST = "Staff List",
  STAFF_MEMBER_DETAILS = "Staff Member Details",
  STAFF_MEMBER_MAKE_ACCOUNT_OWNER_DIALOG = "Staff Member Make Account Owner Dialog",
  STAFF_MEMBER_REMOVE_DIALOG = "Staff Member Remove Dialog",
  ADD_STAFF_MEMBER_DIALOG = "Add Staff Members Dialog",
  ADD_STAFF_MEMBER_SHARE_DIALOG = "Add Staff Member Share Dialog",
  STAFF_INVITATION_DETAILS = "Staff Invitation Details",
  PRODUCT_SHOPPING_LISTS_DIALOG = "Product Shopping Lists Dialog",
  CREATE_SHOPPING_LIST = "Create Shopping List",
  UPDATE_SHOPPING_LIST = "Update Shopping List",
  REMOVE_SELECTED_ITEMS_DIALOG = "Remove Selected Items Dialog",
  MOVE_ITEMS_SELECT_LIST_DIALOG = "Move Items Select List Dialog",
  REMOVE_SHOPPING_LIST_DIALOG = "Remove Shopping List Dialog",
  LIST_OF_SHOPPING_LISTS = "List of Shopping Lists",
  UNUSED_SHOPPING_LIST_ITEMS = "Unused Shopping List Items",
}
